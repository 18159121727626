import RoundedBorader from "mobile/components/text/RoundedBorader";

const ProvideFunc = () => {
  return (
    <section className="w-full h-auto align-col-center gap-10 py-10 bg-[#F5F5F5]">
      <div className="text-center ">
        <RoundedBorader label="FPROVIDED FUNCTION" className="border-none" />
        <h1 className="fade-animation">
          <p className="h2">
            <span className="text-royal-blue">제품</span> 구성
          </p>
        </h1>
      </div>
      <p className="text-center h6 break-keep mx-5">
        M-FLOW는 하이브리드 모바일 앱 개발을 위해 최적화된 구성 모듈을
        제공합니다.
        <br />웹 화면 및 웹 모바일 단말 기능 개발을 위한{" "}
        <span className="text-royal-blue">
          WEB UI COMPONENT / WEB MOBILE APIs
        </span>
        <br />
        모바일 단말 고유 기능 및 3rd Party 기능 사용을 위한{" "}
        <span className="text-royal-blue">
          NATIVE CORE APIs / NATIVE EXTENSION APIs
        </span>
        로 구성됩니다.
      </p>
      <img
        src="/img/solution/mflow2.png"
        alt="문서 변환 기능"
        className="fade-animation w-[300px] h-auto"
      />
    </section>
  );
};

export default ProvideFunc;
