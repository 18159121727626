import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import BrowserPage from "./pc/BrowserPage";
import MobilePage from "./mobile/MobilePage";

function App() {
  return (
    <div>
      <BrowserView>
        <BrowserPage />
      </BrowserView>
      <MobileView>
        <MobilePage />
      </MobileView>
    </div>
  );
}

export default App;
