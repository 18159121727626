import React, { useEffect } from "react";
import MflowIntro from "mobile/container/solution/mflow/MflowIntro";
import ProvideFunc from "mobile/container/solution/mflow/ProvideFunc";
import Effects from "mobile/container/solution/mflow/Effects";
import { fadeInAnimation } from "mobile/animation/fadeIn";

const Mflow = () => {
  useEffect(() => {
    fadeInAnimation();
  }, []);
  return (
    <main>
      <MflowIntro />
      <ProvideFunc />
      <Effects />
    </main>
  );
};

export default Mflow;
