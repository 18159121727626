import React, { useEffect } from "react";
import BusinessHistory from "../container/si/BusinessHistory";
import She from "../container/si/She";
import AuditCentralSys from "../container/si/AuditCentralSys";
import ManufacturingSys from "../container/si/ManufacturingSys";
import StandardDocWatcher from "../container/si/StandardDocWatcher";
import Performance from "../container/si/Performance";
import { fadeInAnimation } from "../animation/fadeIn";

const Si = () => {
  useEffect(() => {
    fadeInAnimation();
  });
  return (
    <main>
      <BusinessHistory />
      <She />
      <AuditCentralSys />
      <ManufacturingSys />
      <StandardDocWatcher />
      <Performance />
    </main>
  );
};

export default Si;
