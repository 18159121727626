import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const nav = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const pathName = window.location.pathname || "/";
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  // mobile
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    // 메뉴가 열렸을 때 스크롤을 막음
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNavigate = (path: string) => {
    navigate(path);
    setIsOpen(false);
    scrollToTop();
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollPos > currentScrollPos;
      setPrevScrollPos(currentScrollPos);
      setVisible(visible);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  //  메뉴가 열리면 스크롤 막기
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = ""; // 메뉴가 닫힐 때 스크롤을 복원
    }
  }, [isOpen]);

  const scrollStyle = () => {
    if (prevScrollPos < 100) {
      if (pathName === "/") return "bg-transparent";
      else return "bg-deep-navy";
    } else if (prevScrollPos < 500) {
      return "bg-deep-navy";
    } else {
      if (visible) return "bg-deep-navy translate-y-0";
      else return "bg-deep-navy -translate-y-[95px]";
    }
  };

  return (
    <nav ref={nav} className="w-full z-30">
      <div
        className={`z-30 navbar flex box-border justify-between items-center h-[73px] w-full px-1 ${scrollStyle()}`}
      >
        <img
          src={"/img/logo_white.png"}
          alt="dayone_logo"
          className="w-[160px] h-[73px] px-5 cursor-pointer"
          onClick={() => handleNavigate("/")}
        />
        <div
          className={`menu-trigger mr-5 ${isOpen ? "active" : ""}`}
          onClick={() => toggleMenu()}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      {isOpen && (
        <ul className="z-20 fixed flex flex-col items-center pt-[120px] w-full h-[100vh] gap-10 px-5 text-white text-xl font-bold bg-black">
          <li
            className={`hover:text-sky ${
              pathName === "/about-us" && "text-sky"
            }`}
          >
            <button type="button" onClick={() => handleNavigate("/about-us")}>
              회사소개
            </button>
          </li>
          <li className={`hover:text-sky ${pathName === "/si" && "text-sky"}`}>
            <button type="button" onClick={() => handleNavigate("/si")}>
              SI
            </button>
          </li>
          <li
            className={`hover:text-sky text-center ${
              pathName === "/solution" && "text-sky"
            }`}
          >
            <button type="button">솔루션</button>
            <ul className=" text-base list-disc list-inside text-left my-3 flex flex-col gap-3">
              <li
                className={`hover:text-sky ${
                  pathName === "/solution/mflow" && "text-sky"
                }`}
              >
                <button
                  type="button"
                  onClick={() => handleNavigate("/solution/mflow")}
                >
                  M-Flow
                </button>
              </li>
              <li
                className={`hover:text-sky ${
                  pathName === "/solution/docblend" && "text-sky"
                }`}
              >
                <button
                  type="button"
                  onClick={() => handleNavigate("/solution/docblend")}
                >
                  Docblend
                </button>
              </li>
            </ul>
          </li>

          <li
            className={`hover:text-sky ${
              pathName === "/contact" && "text-sky"
            }`}
          >
            <button type="button" onClick={() => handleNavigate("/contact")}>
              문의하기
            </button>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Nav;
