import { fadeInAnimation } from "mobile/animation/fadeIn";
import ContactUs from "mobile/container/contact/ContactUs";
import React, { useEffect } from "react";

const Contact = () => {
  useEffect(() => {
    fadeInAnimation();
  }, []);
  return (
    <main>
      <ContactUs />
    </main>
  );
};

export default Contact;
