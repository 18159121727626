import React from "react";
import RoundedBorader from "../../components/text/RoundedBorader";

const Partnership = () => {
  return (
    <section
      className="w-full h-auto gap-10 py-10 overflow-hidden bg-center bg-no-repeat bg-cover align-col-center"
      style={{ backgroundImage: `url(/img/about-us/partnership_bg.jpg)` }}
    >
      <div className="gap-5 mx-5 text-center align-col-center">
        <div>
          <RoundedBorader
            label="KEY PARTNERS"
            className="text-white border-none"
          />
          <h2>
            <p className="text-white fade-animation h2">주요 파트너사</p>
          </h2>
        </div>
        <p className="text-white h6">
          데이원 솔루션(주)은 주요 파트너사인 SK하이닉스, SK C&C, 그 외 기업과
          함께합니다
        </p>
      </div>
      <div className="flex items-center gap-10">
        <img
          src="/img/about-us/SK-hynix.png"
          alt="sk-hynix"
          className="fade-animation w-[100px] h-auto"
        />
        <div className="bg-white w-[1px] h-[80px]" />
        <img
          src="/img/about-us/SK-c&c.png"
          alt="sk-c&c"
          className="fade-animation w-[100px] h-auto"
        />
      </div>
    </section>
  );
};

export default Partnership;
