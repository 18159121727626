import React from "react";
import RoundedBorader from "mobile/components/text/RoundedBorader";
import { HISTORY } from "common/constant/history";

type timelineTypes = {
  year: string;
  item: { label: string; checked: boolean }[];
};

const IndividualTimeline = ({ year, item }: timelineTypes) => {
  return (
    <div className="align-col-center mx-5 text-center">
      <p className="fade-animation h3 text-royal-blue">{year}</p>
      <ul className="h6 my-3 break-keep flex flex-col gap-2">
        {item.map((soloItem, i) => (
          <li key={i} className={soloItem.checked ? "text-gray-300" : ""}>
            · {soloItem.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

const History = () => {
  const TimeLines = HISTORY;
  return (
    <section className="bg-white w-full h-auto align-col-center gap-10 py-10">
      <div className="fade-animation text-center align-col-center">
        <RoundedBorader label="DETAIL BUSINESS" className="border-none" />
        <h2>
          <p className="h2">
            주요 <span className="text-royal-blue">프로젝트</span> 수행{" "}
            <span className="text-royal-blue">연혁</span>
          </p>
        </h2>
      </div>
      <div className="max-w-[425px] w-full mx-5 divide-y-2">
        {TimeLines.map((timeline, i) => (
          <IndividualTimeline
            key={i}
            year={timeline.year}
            item={timeline.item}
          />
        ))}
      </div>
    </section>
  );
};

export default History;
