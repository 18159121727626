import React, { useEffect } from "react";
import Intro from "pc/container/aboutUs/Intro";
import BusinessField from "pc/container/aboutUs/BusinessField";
import Technoliogy from "pc/container/aboutUs/Technoliogy";
import Organization from "pc/container/aboutUs/Organization";
import History from "pc/container/aboutUs/History";
import Partnership from "pc/container/aboutUs/Partnership";
import { fadeInAnimation } from "pc/animation/fadeIn";

const AboutUs = () => {
  useEffect(() => {
    fadeInAnimation();
  });
  return (
    <main>
      <Intro />
      <BusinessField />
      <Technoliogy />
      <Organization />
      <History />
      <Partnership />
    </main>
  );
};

export default AboutUs;
