import React from "react";

const CompanyOverview = () => {
  return (
    <section className="min-w-[978px] w-full h-[1271px] flex flex-col justify-center items-center">
      <img
        src={`/img/logo_color.png`}
        alt="dayone_logo"
        className="fade-animation w-[260px] h-[145px]"
      />
      <span className="h1-t text-center">
        데이원은 <span className="text-royal-blue">제조 통신 금융</span>
        <br />
        <span className="text-royal-blue">SI 컨설팅 서비스</span>​ 를 제공합니다
      </span>
      <div
        className="fade-animation w-[1000px] h-[562px]"
        style={{ backgroundImage: `url(/img/home/three_circle.png)` }}
      >
        <div className="h-[562px] flex gap-16 items-center justify-center text-white text-center pl-5 pt-5">
          <span className="h4-t box ">
            <b>공장 자동화 기술</b>
            <br /> 중심 상품
          </span>
          <span className="h4-t box">
            <b>
              통신 금융분야 플랫폼,
              <br />
              서비스 구축 기반
            </b>
            <br />
            폭넓은 경험
          </span>
          <span className="h4-t box">
            <b>
              AI, BigData
              <br />
              Smart Factory
              <br />
              Business
            </b>{" "}
            영역 확대
          </span>
        </div>
      </div>
      <div className="h6 text-center grid gap-10">
        <span>
          국내 반도체 관련 서비스 개발 사업자로 선도적 기술력과 지속적인
          고객만족 경영을 바탕으로,
          <br />
          <span className="text-royal-blue">
            공장 자동화 기술 중심의 다양한 상품구성을 통한 차별화된
            업무/비지니스 서비스를 제공
          </span>
          합니다.
        </span>
        <span>
          또한 통신 금융분야 플랫폼, 서비스 구축에 많은 경험을 가지고 있으며
          최근에는{" "}
          <span className="text-royal-blue">
            AI, BigData, Smart
            <br /> Factory Business 영역까지 점진적으로 사업을 확대
          </span>
          해 나갈 예정입니다.
        </span>
      </div>
    </section>
  );
};

export default CompanyOverview;
