import React from "react";
import RoundedBorader from "mobile/components/text/RoundedBorader";

const BusinessField = () => {
  return (
    <section
      className="w-full h-auto bg-no-repeat bg-bottom align-col-center"
      style={{ backgroundImage: `url(/img/about-us/business_field.png)` }}
    >
      <div className="fade-animation text-center align-col-center gap-5 bg-white px-5 w-full pt-10 pb-5">
        <div>
          <RoundedBorader label="FIELD OF BUSINESS" className="border-none" />
          <h2>
            <p className="h2">
              <span className="text-royal-blue">사업</span> 분야
            </p>
          </h2>
        </div>
        <p className="h6 break-keep">
          데이원 솔루션(주)은 S/W개발 및 판매, 반도체/환경/지방세/세외수입
          컨설팅 등<br />
          최적의 솔루션과 서비스를 제공합니다
        </p>
      </div>
      <div className="fade-animation w-full flex gap-1 pb-10">
        <div className="w-1/3 h-auto">
          <img src="/img/about-us/tablet.png" alt="S/W 개발" />
          <div className="sub align-center text-center bg-white ">
            <p>
              <span className="text-royal-blue">S/W 개발 및</span>
              <br />
              판매
            </p>
          </div>
        </div>
        <div className="w-1/3 h-auto">
          <img src="/img/about-us/parts.png" alt="반도체, 환경" />
          <div className="sub align-center text-center bg-white ">
            <p>
              <span className="text-royal-blue">반도체 / 환경</span>
              <br />
              컨설팅
            </p>
          </div>
        </div>
        <div className="w-1/3 h-auto">
          <img
            src="/img/about-us/tax_calculation.png"
            alt="지방세 / 세외수입"
          />
          <div className="sub align-center text-center bg-white">
            <p>
              <span className="text-royal-blue">지방세 / 세외수입</span>
              <br />
              컨설팅
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessField;
