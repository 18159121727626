import React, { Suspense } from "react";
import RoundedBorader from "mobile/components/text/RoundedBorader";
import MapNaver from "mobile/components/map/MapNaver";

const ContactUs = () => {
  return (
    <section className="w-full h-auto gap-10 pt-32 pb-10 align-col-center">
      <div className="text-center ">
        <RoundedBorader label="CONTACT US" className="border-none" />
        <h1 className="fade-animation">
          <p className="h2">
            <span className="text-royal-blue">문의</span>
            하기
          </p>
        </h1>
      </div>
      <p className="mx-5 text-center sub break-keep">
        데이원 솔루션(주)에 궁금한 점이 있다면 언제든지 문의주세요. ​최적의
        솔루션으로 보답하겠습니다.
      </p>

      <div className="flex flex-col text-white">
        <div className="w-[220px] h-[220px] bg-light-sky flex flex-col gap-4 pt-6">
          <img
            src="/img/contact/ping_icon.png"
            alt="오시는 길"
            className="w-auto h-[60px] mx-auto"
          />
          <p className="text-xl font-semibold text-center">오시는 길</p>
          <p className="text-base font-normal text-center break-keep">
            서울 강남구 강남대로62길 28
            <br />
            4층 데이원 솔루션(주)
          </p>
        </div>

        <div className="w-[220px] h-[220px] bg-sky flex flex-col gap-4 pt-6">
          <img
            src="/img/contact/strc_icon.png"
            alt="문의하기"
            className="w-auto h-[60px] mx-auto"
          />
          <p className="text-xl font-semibold text-center">문의하기</p>
          <p className="pt-4 text-base font-normal text-center break-keep">
            <a data-auto-recognition="true" href="mailto:jylee@dayone1.kr">
              jylee@dayone1.kr
            </a>
          </p>
        </div>

        <div className="w-[220px] h-[220px] bg-deep-indigo flex flex-col gap-4 pt-6 ">
          <img
            src="/img/contact/phone_icon.png"
            alt="회사소개서"
            className="w-auto h-[60px] mx-auto"
          />
          <p className="text-xl font-semibold text-center">회사소개서</p>
          <button className="border border-white w-40 py-1.5 text-xl mx-auto hover:bg-indigo hover:text-white">
            <a href="/pdf/dayone.pdf" target="_blank">
              다운 로드 {">"}
            </a>
          </button>
        </div>
      </div>
      <Suspense fallback={null}>
        <div className="bg-white max-w-[425px] w-full box-border h-[300px] mt-5 mx-auto">
          <MapNaver />
        </div>
      </Suspense>
    </section>
  );
};

export default ContactUs;
