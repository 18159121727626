import { Image } from "antd";
import RoundedBorader from "pc/components/text/RoundedBorader";

const Docblend = () => {
  return (
    <section className="relative min-w-[980px] w-full h-[1100px] align-col-center">
      <div className="gap-20 background-blur align-col-center">
        <div className="text-center ">
          <RoundedBorader label="DAYONE SOLUTION" className="border-none" />
          <h1 className="fade-animation">
            <p className="h1 text-royal-blue">M-FLOW</p>
          </h1>
        </div>
        <Image
          width={700}
          src="/img/solution/mflow.png"
          alt="mflow"
          className="fade-animation"
          preview={false}
        />
        <p className="text-center sub">
          기업 모바일 앱 서비스, M-FLOW라면 어렵지 않습니다.
          <br />
          웹 표준 기술인 HTML5, CSS, JavaScript로 모바일 앱 서비스가 가능해
          집니다.
          <br />
          기업의{" "}
          <span className="text-royal-blue">
            모바일 앱 서비스를 가장 쉽고 빠르게 제공하는 방법 M-FLOW
          </span>
          와 함께하세요.
        </p>
      </div>
    </section>
  );
};

export default Docblend;
