import { fadeInAnimation } from "mobile/animation/fadeIn";
import AuditCentralSys from "mobile/container/si/AuditCentralSys";
import BusinessHistory from "mobile/container/si/BusinessHistory";
import ManufacturingSys from "mobile/container/si/ManufacturingSys";
import Performance from "mobile/container/si/Performance";
import She from "mobile/container/si/She";
import StandardDocWatcher from "mobile/container/si/StandardDocWatcher";
import React, { useEffect } from "react";

const Si = () => {
  useEffect(() => {
    fadeInAnimation();
  }, []);
  return (
    <main>
      <BusinessHistory />
      <She />
      <AuditCentralSys />
      <ManufacturingSys />
      <StandardDocWatcher />
      <Performance />
    </main>
  );
};

export default Si;
