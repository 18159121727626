import React from "react";
import RoundedBorader from "pc/components/text/RoundedBorader";

const BusinessField = () => {
  return (
    <section
      className="min-w-[980px] w-full h-[1200px] bg-bottom bg-no-repeat align-col-center gap-20"
      style={{ backgroundImage: `url(/img/about-us/business_field.png)` }}
    >
      <div className="fade-animation text-center align-col-center gap-2">
        <RoundedBorader label="FIELD OF BUSINESS" className="border-none" />
        <h2 className="h2 text-5xl font-bold">
          <span className="text-royal-blue">사업</span> 분야
        </h2>
        <p className="h5-t tracking-wide mt-8 text-[#1E1E1E]">
          데이원 솔루션(주)은 S/W개발 및 판매, 반도체/환경/지방세/세외수입
          컨설팅 등<br />
          최적의 솔루션과 서비스를 제공합니다
        </p>
      </div>
      <div className="fade-animation flex gap-7">
        <div>
          <img src="/img/about-us/tablet.png" alt="S/W 개발" />
          <div className="text-md align-center text-center bg-white w-[330px] h-[200px]">
            <p>
              <span className="text-royal-blue">S/W 개발 및</span>
              <br />
              판매
            </p>
          </div>
        </div>
        <div>
          <img src="/img/about-us/parts.png" alt="반도체 / 환경" />
          <div className="text-md align-center text-center bg-white w-[330px] h-[200px]">
            <p>
              <span className="text-royal-blue">반도체 / 환경</span>
              <br />
              컨설팅
            </p>
          </div>
        </div>
        <div>
          <img
            src="/img/about-us/tax_calculation.png"
            alt="지방세 / 세외수입"
          />
          <div className="text-md align-center text-center bg-white w-[330px] h-[200px]">
            <p>
              <span className="text-royal-blue">지방세 / 세외수입</span>
              <br />
              컨설팅
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessField;
