import React, { Suspense } from "react";
import RoundedBorader from "pc/components/text/RoundedBorader";
import MapNaver from "pc/components/map/MapNaver";

const ContactUs = () => {
  return (
    <section className="min-w-[980px] w-full h-[1380px] align-col-center gap-10">
      <div className="text-center ">
        <RoundedBorader label="CONTACT US" className="border-none" />
        <h1 className="fade-animation">
          <p className="h1">
            <span className="text-royal-blue">문의</span>
            하기
          </p>
        </h1>
      </div>
      <p className="text-center sub">
        데이원 솔루션(주)에 궁금한 점이 있다면 언제든지 문의주세요.
        <br />
        ​최적의 솔루션으로 보답하겠습니다.
      </p>
      <div className="flex text-white">
        <div className="w-[300px] h-[300px] bg-light-sky flex pt-11 flex-col gap-5">
          <img
            src="/img/contact/ping_icon.png"
            alt="오시는 길"
            className="w-auto h-[80px] mx-auto"
          />
          <p className="text-center text-[32px] font-semibold">오시는 길</p>
          <p className="text-xl font-normal text-center break-keep">
            서울 강남구 강남대로62길 28
            <br />
            4층 데이원 솔루션(주)
          </p>
        </div>
        <div className="w-[300px] h-[300px] bg-sky flex pt-11 flex-col gap-5">
          <img
            src="/img/contact/phone_icon.png"
            alt="문의하기"
            className="w-auto h-[80px] mx-auto"
          />
          <p className="text-center text-[32px] font-semibold">문의하기</p>
          <p className="text-xl font-normal text-center break-keep">
            <a data-auto-recognition="true" href="mailto:jylee@dayone1.kr">
              jylee@dayone1.kr
            </a>
          </p>
        </div>
        <div className="w-[300px] h-[300px] bg-deep-indigo flex pt-11 flex-col gap-5">
          <img
            src="/img/contact/strc_icon.png"
            alt="회사소개서"
            className="w-auto h-[80px] mx-auto"
          />
          <p className="text-center text-[32px] font-semibold">회사소개서</p>
          <button className="border-2 border-white w-full px-10 py-1.5 text-xl hover:bg-indigo hover:text-white">
            <a href="/pdf/dayone.pdf" target="_blank">
              다운 로드 {">"}
            </a>
          </button>
        </div>
      </div>
      <Suspense fallback={null}>
        <div className="bg-white max-w-[980px] w-full box-border h-[400px] mt-10 mx-auto">
          <MapNaver />
        </div>
      </Suspense>
    </section>
  );
};

export default ContactUs;
