import { fadeInAnimation } from "mobile/animation/fadeIn";
import Effects from "mobile/container/solution/docblend/Effects";
import Intro from "mobile/container/solution/docblend/Intro";
import ProvideFunc from "mobile/container/solution/docblend/ProvideFunc";
import React, { useEffect } from "react";

const Docblend = () => {
  useEffect(() => {
    fadeInAnimation();
  }, []);
  return (
    <main>
      <Intro />
      <ProvideFunc />
      <Effects />
    </main>
  );
};

export default Docblend;
