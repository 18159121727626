import React from "react";

type PropTypes = {
  label: string;
  className?: string;
};
const RoundedBorader = ({ label, className }: PropTypes) => {
  return (
    <p
      className={`fade-animation sub-thin border-indigo border py-1 px-5 rounded-full ${className}`}
    >
      {label}
    </p>
  );
};

export default RoundedBorader;
