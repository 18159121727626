import React from "react";
import RoundedBorader from "pc/components/text/RoundedBorader";

const StandardDocWatcher = () => {
  return (
    <section className="w-full h-[1250px] min-w-[980px] align-col-center gap-10">
      <RoundedBorader label="MAJOR BUSINESS HISTORY 4" />
      <h1>
        <p className="fade-animation h1">
          <span className="text-royal-blue">문서 표준화 관리</span> 시스템 구축
        </p>
      </h1>
      <img
        src="/img/si/document_manage.webp"
        alt="문서 표준화 관리시스템"
        className="fade-animation w-[1260px] h-[668px]"
      />
      <p className="sub text-center">
        기업 내에서 활용되고 있는 지식기반 문서 및 데이터에 대한 관리가
        필요합니다.
        <br />
        문서 및 프로세스 표준화를 통하여{" "}
        <span className="text-royal-blue">업무 프로세스 개선 효과</span>를 볼 수
        있습니다.
        <br />
        기업 내에서 발생되는{" "}
        <span className="text-royal-blue">
          지식 자산에 대한 관리 기능을 제공
        </span>
        하며
        <br />
        향후{" "}
        <span className="text-royal-blue">
          업무 프로세스 표준화를 위한 배포 및 연계 기능을 제공
        </span>
        합니다.
        <br />
      </p>
    </section>
  );
};

export default StandardDocWatcher;
