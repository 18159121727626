import RoundedBorader from "pc/components/text/RoundedBorader";

const Effects = () => {
  return (
    <section className="min-w-[980px] w-full h-[870px] align-col-center gap-16">
      <div className="text-center ">
        <RoundedBorader
          label="EXPECTED EFFECTS OF INTRODUCTION"
          className="border-none"
        />
        <h1 className="fade-animation">
          <p className="h1">
            도입 <span className="text-royal-blue">기대 효과</span>
          </p>
        </h1>
      </div>

      <div className="flex flex-col gap-10">
        <div className="grid grid-cols-4 gap-16">
          <div className="col-span-1">
            <div className="flex items-center justify-center w-32 h-32 text-3xl font-bold text-white rounded-full bg-deep-navy">
              기술
            </div>
          </div>
          <div className="flex flex-col h-32 col-span-3 gap-2 border-b-4 border-blue-300">
            <span className="text-2xl font-semibold text-black">
              기술 의존성 탈피
            </span>
            <span className="text-xl font-light">
              Web 표준 기술로 서비스 개발
              <br />
              특정 플랫폼에 종속된 기술/인력 보유 불필요
            </span>
          </div>
        </div>

        <div className="grid grid-cols-4 gap-16">
          <div className="col-span-1">
            <div className="flex items-center justify-center w-32 h-32 text-3xl font-bold text-white rounded-full bg-deep-navy">
              효율
            </div>
          </div>
          <div className="flex flex-col h-32 col-span-3 gap-2 border-b-4 border-blue-300">
            <span className="text-2xl font-semibold text-black">
              생산/관리 효율성
            </span>
            <span className="text-xl font-light">
              Web 표준 기술로 서비스 개발
              <br />
              소스 코드의 재사용성 향상. 운영/유지 보수 비용 절감
            </span>
          </div>
        </div>

        <div className="grid w-full grid-cols-4 gap-16">
          <div className="col-span-1">
            <div className="flex items-center justify-center w-32 h-32 text-3xl font-bold text-white rounded-full bg-deep-navy">
              확장
            </div>
          </div>
          <div className="flex flex-col h-32 col-span-3 gap-2 border-b-4 border-blue-300">
            <span className="text-2xl font-semibold text-black">
              기능 확장성
            </span>
            <span className="text-xl font-light">
              모바일 단말의 기능 및 기술 트랜드의 빠른 변화
              <br />
              M-FLOW의 확장 APIs를 통해 서비스 내 빠른 신 기술 및 기능 도입
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Effects;
