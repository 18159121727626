import { Image } from "antd";
import RoundedBorader from "pc/components/text/RoundedBorader";

const ProvideFunc = () => {
  return (
    <section className="min-w-[980px] w-full h-[1000px] align-col-center gap-10 bg-[#F5F5F5]">
      <div className="text-center ">
        <RoundedBorader label="FPROVIDED FUNCTION" className="border-none" />
        <h1 className="fade-animation">
          <p className="h1">
            <span className="text-royal-blue">제품</span> 구성
          </p>
        </h1>
      </div>
      <p className="text-center sub">
        M-FLOW는 하이브리드 모바일 앱 개발을 위해 최적화된 구성 모듈을
        제공합니다.
        <br />웹 화면 및 웹 모바일 단말 기능 개발을 위한{" "}
        <span className="text-royal-blue">
          WEB UI COMPONENT / WEB MOBILE APIs
        </span>
        <br />
        모바일 단말 고유 기능 및 3rd Party 기능 사용을 위한{" "}
        <span className="text-royal-blue">
          NATIVE CORE APIs / NATIVE EXTENSION APIs
        </span>
        <br />로 구성됩니다.
      </p>
      <Image
        width={800}
        src="/img/solution/mflow2.png"
        alt="문서 변환 기능"
        className="fade-animation"
        preview={false}
      />
    </section>
  );
};

export default ProvideFunc;
