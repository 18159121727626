import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "mobile/pages/Home";
import Footer from "mobile/components/footer/Footer";
import Nav from "mobile/components/navigation/Nav";
import AboutUs from "mobile/pages/AboutUs";
import Si from "mobile/pages/Si";
import Contact from "mobile/pages/Contact";
import Mflow from "mobile/pages/solution/Mflow";
import Docblend from "mobile/pages/solution/Docblend";
import ScrollTopButton from "mobile/components/button/ScrollTopButton";

const MobilePage = () => {
  return (
    <div className="mobile-root bg-black relative">
      <ScrollTopButton />
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/si" element={<Si />} />
        <Route path="/solution/mflow" element={<Mflow />} />
        <Route path="/solution/docblend" element={<Docblend />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default MobilePage;
