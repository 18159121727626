import React from "react";
type OverviewBoxType = {
  icon: string;
  category: string;
  sub: string;
  desc: string[];
};
const OverviewBox = ({ icon, category, sub, desc }: OverviewBoxType) => {
  return (
    <div className="align-center gap-2 w-full h-auto bg-white text-[#1E1E1E] border-2 border-light-text-gray rounded-3xl p-2">
      <div>
        <img
          src={`/icon/${icon}.png`}
          alt={category}
          className="w-[30px] h-[30px] mx-auto"
        />
        <p className="h6 text-center my-1 min-w-[70px]">
          <span className="text-sky">{category}</span>
          {sub}
        </p>
      </div>
      <ul className="sub w-full inline-flex flex-col gap-2 font-normal text-left break-keep">
        {desc.map((item, i) => (
          <li key={i}>· {item}</li>
        ))}
      </ul>
    </div>
  );
};

const MainBusinessSegments = () => {
  return (
    <section className="bg-royal-gray w-full h-auto flex flex-col items-center">
      <div className="fade-animation flex justify-center w-full max-w-[360px]">
        <div className="w-1/3">
          <img
            src={"/img/home/manufact_part.png"}
            alt="반도체 IT 기술"
            className="w-full h-auto"
          />
          <div className="w-full h-auto bg-[#798EBF] flex items-center justify-center">
            <p className="sub text-white text-center">
              반도체 기반
              <br />
              <b>제조 IT 기술</b>
            </p>
          </div>
        </div>
        <div className="w-1/3">
          <img
            src={"/img/home/mobile_app.png"}
            alt="통신 금융 플랫폼"
            className="w-full h-auto"
          />
          <div className="w-full h-auto bg-[#485265] flex items-center justify-center">
            <p className="sub text-white text-center">
              <b>통신 금융 플랫폼</b>
              <br />
              구축 경험
            </p>
          </div>
        </div>
        <div className="w-1/3">
          <img
            src={"/img/home/management.png"}
            alt="SI/SM"
            className="w-full h-auto"
          />
          <div className="w-full h-auto bg-[#1D2546] flex items-center justify-center">
            <p className="sub text-white text-center">
              <b>다양한 SI/SM</b>
              <br />
              프로젝트 관리
            </p>
          </div>
        </div>
      </div>
      <div className="bg-royal-blue w-[2px] h-[60px] mx-auto my-10" />
      <div className="fade-animation text-center">
        <p className="sub text-light-text-gray">DayOne's main business area</p>
        <h2>
          <p className="h2 font-bold">
            데이원의 <span className="text-royal-blue">주요 사업</span> 분야
          </p>
        </h2>
      </div>
      <img
        src={"/img/home/dayone_structor.png"}
        alt="데이원 주요 사업 분야"
        className="fade-animation mx-auto h-auto w-full max-w-[425px]"
      />
      <div className="align-col-center gap-5 my-10 px-2">
        <OverviewBox
          icon={`manufacturing`}
          category="제조"
          sub="기술"
          desc={[
            "SK하이닉스 SHE 화학물질 관리시스템",
            "SK하이닉스 FAB Wafer 기반 가동률",
            "SK하이닉스 Global HeSS (전자표준문서관리 시스템)",
          ]}
        />
        <OverviewBox
          icon={`network`}
          category="통신"
          sub="기술"
          desc={[
            "SMS/MMS 인프라",
            "Connected Car 플랫폼",
            "CCTV 플랫폼",
            "loT 플랫폼",
          ]}
        />
        <OverviewBox
          icon={`finance`}
          category="금융"
          sub="업무"
          desc={[
            "은행 인터넷 뱅킹",
            "보험 계약 관리",
            "카드 광고 관리",
            "증권사 채팅 서비스",
          ]}
        />
        <OverviewBox
          icon={`public`}
          category="공공"
          sub="업무"
          desc={[
            "서울시 세입업무",
            "우정사업본부 압류업무",
            "장학재단 대출업무",
            "Smart Hospital",
          ]}
        />
        <OverviewBox
          icon={`social`}
          category=""
          sub="기타"
          desc={[
            "MSA 기반 플랫폼 구축",
            "실시간 채팅 / 영상 서비스",
            "대용량 / 대규모 플랫폼 처리 기술",
          ]}
        />
      </div>
    </section>
  );
};

export default MainBusinessSegments;
