import React from "react";
import RoundedBorader from "../../components/text/RoundedBorader";

const AuditCentralSys = () => {
  return (
    <section className="w-full h-auto align-col-center gap-10 py-10">
      <RoundedBorader label="MAJOR BUSINESS HISTORY 2" />
      <h1>
        <p className="fade-animation h2 text-royal-blue">Audit Central Sys</p>
      </h1>
      <img
        src="/img/si/m_audit.png"
        alt="Audit 시스템"
        className="fade-animation w-[240x] max-w-[240px] h-auto"
      />
      <p className="h6 text-center break-keep mx-5">
        제조 설비는 다양한 제조사 및 모델이 있으며 공정 변경 등 OP Panel에서
        발생된 변경 이력을 통합적으로 수집, 관리 할 수 있는 시스템이 요구됩니다.
        <br />
        <span className="text-royal-blue">
          ​Audit Trail 시스템은 다양한 제조사와 모델 설비들의 변경 이력을
          통합적으로 관리할 수 있는 기능을 제공
        </span>
        합니다.
      </p>
    </section>
  );
};

export default AuditCentralSys;
