import React from "react";
import RoundedBorader from "pc/components/text/RoundedBorader";

const She = () => {
  return (
    <section className="w-full h-[1607px] min-w-[980px] align-col-center gap-10 bg-[#F5F5F5]">
      <RoundedBorader label="MAJOR BUSINESS HISTORY 1" />
      <div className="fade-animation text-center">
        <h1>
          <p className="h1 text-royal-blue">SHE</p>
        </h1>
        <p className="h2 tracking-tighter">
          (<span className="text-royal-blue">S</span>afety,{" "}
          <span className="text-royal-blue">H</span>ealth,{" "}
          <span className="text-royal-blue">E</span>nviroment)
        </p>
      </div>
      <img
        src="/img/si/she_diagram.png"
        alt="SHE 구성도"
        className="fade-animation w-[720px] h-auto"
      />
      <p className="sub text-center">
        국내 반도체 관련 서비스 개발 사업자로 선도적 기술력과 지속적인 고객만족
        경영을 바탕으로,
        <br />
        <span className="text-royal-blue">
          보건/환경/안전(SHE) 중심의 다양한 상품구성을 통한 차별화된
          업무/비즈니스 서비스를 제공
        </span>
        합니다.
        <br />
        또한 화학물질에 MSDS 및 공정안전자료 관리(PSM), 독성가스 감지기
        관리(TGMS) 등{" "}
        <span className="text-royal-blue">
          SHE에 관련된
          <br />
          상세 업무 개발에 많은 경험과 노하우가 있는 (주)데이원
        </span>
        입니다.
      </p>
    </section>
  );
};

export default She;
