import React from "react";
import RoundedBorader from "pc/components/text/RoundedBorader";

const Performance = () => {
  return (
    <section className="w-full h-[1536px] min-w-[980px] align-col-center gap-10 bg-[#F5F5F5]">
      <div className="text-center">
        <RoundedBorader label="FPROVIDED FUNCTION" className="border-none" />
        <h1>
          <p className="fade-animation h1">
            주요 <span className="text-royal-blue">프로젝트 수행</span> 경험
          </p>
        </h1>
        <p className="sub mt-10">
          제조, 금융, 통신 각 전문 분야에서 10년 이상 프로젝트를 진행한 경험을
          바탕으로 <br />
          ​다양한 서비스 및 시스템을 구축 할 수 있는 역량을 가지고 있습니다.
        </p>
      </div>
      <img
        src="/img/si/project.webp"
        alt="프로젝트"
        className="fade-animation w-[979px] h-[1122px]"
      />
    </section>
  );
};

export default Performance;
