import React, { useEffect } from "react";
import Docblend from "pc/container/solution/docblend/Docblend";
import ProvideFunc from "pc/container/solution/docblend/ProvideFunc";
import Effects from "pc/container/solution/docblend/Effects";
import { fadeInAnimation } from "pc/animation/fadeIn";

const Solution = () => {
  useEffect(() => {
    fadeInAnimation();
  });
  return (
    <div>
      <Docblend />
      <ProvideFunc />
      <Effects />
    </div>
  );
};

export default Solution;
