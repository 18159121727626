import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const form = useRef<HTMLFormElement>(null);

  // 환경 변수
  const {
    REACT_APP_MAIL_SERVICE_ID,
    REACT_APP_MAIL_TEMPLATE_ID,
    REACT_APP_MAIL_PUBLIC_KEY,
  } = process.env;

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm(
        REACT_APP_MAIL_SERVICE_ID as string,
        REACT_APP_MAIL_TEMPLATE_ID as string,
        form.current as any,
        {
          publicKey: REACT_APP_MAIL_PUBLIC_KEY as string,
        }
      )
      .then(
        () => {
          console.log("SUCCESS!");
          alert("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
          console.error(error.message);
          alert("FAILED!");
        }
      );
  };
  return (
    <form
      ref={form}
      onSubmit={sendEmail}
      className="grid grid-rows-5 grid-cols-2 gap-3"
    >
      <div className="row-span-1">
        <label htmlFor="firstName" className="text-sm font-medium text-white">
          First name
        </label>
        <input
          type="text"
          name="firstName"
          id="firstName"
          placeholder="First name"
          className="w-full rounded-md border border-[#D0D5DD] bg-white py-3 px-6 text-xs text-black outline-none focus:border-[#6A64F1] focus:shadow-md"
          required
        />
      </div>
      <div className="row-span-1">
        <label htmlFor="lastName" className="text-sm font-medium text-white">
          Last name
        </label>
        <input
          type="text"
          name="lastName"
          id="lastName"
          placeholder="Last name"
          className="w-full rounded-md border border-[#D0D5DD] bg-white py-3 px-6 text-xs text-black outline-none focus:border-[#6A64F1] focus:shadow-md"
          required
        />
      </div>
      <div className="col-span-2">
        <label htmlFor="email" className="text-sm font-medium text-white">
          Email
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="you@company.com"
          className="w-full rounded-md border border-[#D0D5DD] bg-white py-3 px-6 text-xs text-black outline-none focus:border-[#6A64F1] focus:shadow-md"
          required
        />
      </div>
      <div className="col-span-2 row-span-2">
        <label htmlFor="message" className="text-sm font-medium text-white">
          Message
        </label>
        <textarea
          rows={4}
          name="message"
          id="message"
          placeholder="Type your message"
          className="w-full resize-none rounded-md border border-[#D0D5DD] bg-white py-3 px-6 text-xs text-black outline-none focus:border-[#6A64F1] focus:shadow-md"
          required
        />
      </div>
      <div className="col-span-2">
        <button className="w-full hover:shadow-form rounded-md bg-sky py-3 px-8 text-sm font-semibold text-white outline-none">
          Send Message
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
