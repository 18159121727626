import React, { useState, useEffect } from "react";
import {
  Container as MapDiv,
  NaverMap,
  Marker,
  useNavermaps,
  InfoWindow,
} from "react-naver-maps";

const MapNaver = () => {
  const navermaps = useNavermaps();

  const lat = 37.49069340496396;
  const lng = 127.03381525436687;

  const contentString = [
    '<div style="background-color: white; padding: 12px 18px; border: 0px solid transparent; display: block; max-width: none; max-height: none; box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);">',
    '<p style="font-weight: bold; font-size: 12px;">데이원 솔루션 (주)</p>',
    '<p style="font-weight: light;">서울 강남구 강남대로 62길 28 4층</p>',
    "</div>",
  ].join("");

  // useRef 대신 useState를 통해 ref를 가져옵니다.
  const [map, setMap] = useState<any>(null);
  const [infowindow, setInfoWindow] = useState<any>(null);
  const [marker, setMarker] = useState<any>(null);

  useEffect(() => {
    if (!map || !infowindow) {
      return;
    }
    infowindow.open(map, marker);
  }, [map, infowindow, marker]);

  const handleMarkerClick = () => {
    if (infowindow.getMap()) infowindow.close();
    else infowindow.open(map, marker);
  };

  return (
    <MapDiv
      style={{
        position: "relative",
        width: "100%",
        height: "300px",
      }}
    >
      <NaverMap
        defaultCenter={new navermaps.LatLng(lat, lng)}
        defaultZoom={15}
        zoomControl={true}
        defaultMapTypeId={navermaps.MapTypeId.NORMAL}
        ref={setMap}
      >
        <Marker
          defaultPosition={new navermaps.LatLng(lat, lng)}
          ref={setMarker}
          onClick={handleMarkerClick}
        />
        <InfoWindow
          content={contentString}
          disableAnchor={false}
          disableAutoPan={true}
          ref={setInfoWindow}
        />
      </NaverMap>
    </MapDiv>
  );
};

export default MapNaver;
