import React from "react";
import { Routes, Route } from "react-router-dom";
import Nav from "pc/components/navigation/Nav";
import Home from "pc/pages/Home";
import AboutUs from "pc/pages/AboutUs";
import Si from "pc/pages/Si";
import Mflow from "pc/pages/solution/Mflow";
import Dcoblend from "pc/pages/solution/Docblend";
import Contact from "pc/pages/Contact";
import Footer from "pc/components/footer/Footer";

const BrowserPage = () => {
  return (
    <div className="pc-root">
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/si" element={<Si />} />
        <Route path="/solution/mflow" element={<Mflow />} />
        <Route path="/solution/docblend" element={<Dcoblend />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default BrowserPage;
