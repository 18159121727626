import React from "react";
import RoundedBorader from "pc/components/text/RoundedBorader";

const Organization = () => {
  return (
    <section
      className="min-w-[980px] w-full h-[1400px] bg-cover bg-center bg-no-repeat align-col-center gap-20"
      style={{ backgroundImage: `url(/img/about-us/organization_bg.jpg)` }}
    >
      <div className="gap-2 text-center fade-animation align-col-center">
        <RoundedBorader
          label="CERTIFICATION STATUS"
          className="text-white border-none"
        />
        <h2 className="text-5xl font-bold text-white h2">조직도</h2>
      </div>
      <img
        src="/img/about-us/dayone_organization.png"
        alt="데이원 조직도"
        className="fade-animation "
      />
    </section>
  );
};

export default Organization;
