import { useEffect } from "react";
import Mflow from "pc/container/solution/mflow/mflow";
import ProvideFunc from "pc/container/solution/mflow/ProvideFunc";
import Effects from "pc/container/solution/mflow/Effects";
import { fadeInAnimation } from "pc/animation/fadeIn";

const Solution = () => {
  useEffect(() => {
    fadeInAnimation();
  });
  return (
    <div>
      <Mflow />
      <ProvideFunc />
      <Effects />
    </div>
  );
};

export default Solution;
