import RoundedBorader from "mobile/components/text/RoundedBorader";
import React from "react";

const Intro = () => {
  return (
    <section className="w-full h-auto align-col-center pt-32 pb-10">
      <div className="gap-10 background-blur align-col-center">
        <div className="text-center ">
          <RoundedBorader label="DAYONE SOLUTION" className="border-none" />
          <h1 className="fade-animation">
            <p className="h2 text-royal-blue">DOCBLEND</p>
          </h1>
        </div>
        <img
          src="/img/solution/docblend.webp"
          alt="docblend"
          className="fade-animation w-[240px] h-auto"
        />
        <p className="text-center h6 break-keep mx-5">
          기업 내 자산 데이터가 선도적인 디지털 기술을 통해 처리 가능할 때
          기업은 혁신적이고 효율적인 업무 경험을 만들어 낼 수 있으며 이러한 업무
          경험을 통해 새로운 가치 창출의 기회를 얻을 수 있습니다.
        </p>
        <p className="text-center h6 break-keep mx-5">
          <span className="text-royal-blue">
            ​문서 변환 기능을 통해 기업 내 축적된 문서 자산을 디지털 기술과 연동
            가능한 데이터 자산으로 변환하는 기능을 제공
          </span>
          합니다.
        </p>
      </div>
    </section>
  );
};

export default Intro;
