import React, { Suspense, useEffect } from "react";
import Intro from "mobile/container/home/Intro";
import CompanyOverview from "mobile/container/home/CompanyOverview";
import MainBusinessSegments from "mobile/container/home/MainBusinessSegments";
import Contact from "mobile/container/home/Contact";
import { fadeInAnimation } from "mobile/animation/fadeIn";

const Home = () => {
  useEffect(() => {
    fadeInAnimation();
  }, []);
  return (
    <main>
      <Intro />
      <CompanyOverview />
      <MainBusinessSegments />
      <Suspense fallback={null}>
        <Contact />
      </Suspense>
    </main>
  );
};

export default Home;
