import React from "react";

const Footer = () => {
  return (
    <footer className="bg-black text-white text-base py-10 w-full">
      <div className="max-w-[1377px] mx-auto flex flex-col gap-1">
        <div className="align-between">
          <div className="flex flex-col gap-1">
            <h1 className="my-5 text-xl font-bold">데이원 솔루션(주)</h1>
            <p>서울 강남구 강남대로 62길 28 4층</p>
            <p>대표이사 : 신재형 이재영</p>
            <p>대표번호 : 02)422-7532</p>
            <p>팩스 : 0504-015-8532</p>
          </div>
          <img
            src="/img/logo_white.png"
            alt="logo"
            className="w-[284x] h-[160px]"
          />
        </div>

        <div className="mb-5 mt-10">
          <hr></hr>
          <p className="py-3">
            Copyright &copy; DayOne Solution. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
