import React from "react";
import RoundedBorader from "pc/components/text/RoundedBorader";

const Technoliogy = () => {
  return (
    <section className="min-w-[980px] bg-white w-full h-[1400px] align-col-center gap-10">
      <div className="text-center align-col-center gap-2">
        <RoundedBorader label="RETAINED TECHNOLIOGY" className="border-none" />
        <h2 className="fade-animation h2 text-5xl font-bold">
          <span className="text-royal-blue">사업</span> 분야
        </h2>
        <p className="h5-t tracking-wide mt-8 text-[#1E1E1E]">
          데이원 솔루션(주)은 자바 플랫폼, C#, RDBMS(Oracle, Postages, MySql),
          React
          <br />
          기술을 보유함으로써 최적의 솔루션을 도출하여 효율적이고 안정적인
          사업을 수행합니다
        </p>
      </div>
      <img
        src="/img/about-us/skill_stack.png"
        alt="dayone skill"
        className="fade-animation h-[790px] w-auto"
      />
    </section>
  );
};

export default Technoliogy;
