interface TimelineTypes {
  year: string;
  item: { label: string; checked: boolean }[];
}

export const HISTORY: TimelineTypes[] = [
  {
    year: "2023",
    item: [
      {
        label: "SK하이닉스 보건/안전/환경 개발",
        checked: false,
      },
      {
        label: "SK바이오텍 Audit Trail 시스템 구축",
        checked: false,
      },
    ],
  },
  {
    year: "2022",
    item: [
      { label: "SK하이닉스 P&T Wafer 기반 가동률 구축", checked: false },
      {
        label: "SK하이닉스 Global HeSS (전자표준문서관리 시스템) 개발",
        checked: false,
      },
      {
        label: "AIA생명 ATIS 고객계약 관리 시스템 고도화",
        checked: false,
      },
    ],
  },
  {
    year: "2021",
    item: [
      { label: "SK하이닉스 FAB Wafer 기반 가동률 구축", checked: false },
      {
        label: "SK하이닉스 FAB R4 가동률 구축",
        checked: false,
      },
      {
        label: "롯데카드 AI광고 심의시스템 구축",
        checked: false,
      },
    ],
  },
  {
    year: "2020",
    item: [
      {
        label: "SK하이닉스 반도체 트러블 장비 모니터링 시스템 구축",
        checked: false,
      },
      {
        label: "SK하이닉스 FAB 가동률 업무 개발",
        checked: false,
      },
      {
        label: "유안타증권 채팅 솔루션 구축",
        checked: false,
      },
      {
        label: "CJ메조미디어 실시간 영상기반 퀴즈 서비스 개발, 유지보수",
        checked: false,
      },
    ],
  },
  {
    year: "~2019",
    item: [
      { label: "SK하이닉스 SHE 화학물질 관리 시스템 개발", checked: true },
      {
        label: "쌍용차 커넥티드카 플랫폼 구축",
        checked: true,
      },
      { label: "경남은행 인터넷뱅킹 개발, 유지보수", checked: true },
      {
        label: "KT 후후 플랫폼 고도화",
        checked: true,
      },
      {
        label: "도요타 커넥티드카 플랫폼 구축",
        checked: true,
      },
    ],
  },

  {
    year: "~2016",
    item: [
      {
        label: "우정사업본부 압류 유지보수",
        checked: true,
      },
      {
        label: "SK하이닉스 TGMS 감지기 신청관리/모니터링 시스템 구축",
        checked: true,
      },
      {
        label: "장학재단 소득분위별 대출 개발",
        checked: true,
      },
      {
        label: "SKBroadBand Smart Home CCTV 안심캠2 플랫폼 구축",
        checked: true,
      },
      {
        label: "롯데 L-Pay 플랫폼 구축",
        checked: true,
      },
    ],
  },
  {
    year: "~2009",
    item: [
      { label: "서울시 표준지방세 개발", checked: true },
      {
        label: "KT MMS Gateway 구축, 유지보수",
        checked: true,
      },
    ],
  },
  {
    year: "2004",
    item: [
      { label: "서울시 세입업무 통합 개발", checked: true },
      {
        label: "LGT MMS(Multimedia Messaging Service) Center 구축, 유지보수",
        checked: true,
      },
    ],
  },
];
