import React from "react";
import RoundedBorader from "pc/components/text/RoundedBorader";
import { Timeline } from "antd";
import { HISTORY } from "common/constant/history";

type IndividualTime = {
  label: string;
  className: string;
};
const IndividualTimeline = ({ label, className }: IndividualTime) => {
  return (
    <span className={`font-medium text-lg break-keep ${className}`}>
      {label}
    </span>
  );
};

const BlueDot = () => {
  return <div className="w-3 h-3 rounded-full bg-[#637FB6]" />;
};

const History = () => {
  const TimeLines = HISTORY;
  return (
    <section className="my-[120px] bg-white w-full h-fit align-col-center gap-20">
      <div className="fade-animation text-center align-col-center gap-2">
        <RoundedBorader label="DETAIL BUSINESS" className="border-none" />
        <h2>
          <p className="h2">
            주요 <span className="text-royal-blue">프로젝트</span> 수행{" "}
            <span className="text-royal-blue">연혁</span>
          </p>
        </h2>
      </div>
      <div className="flex flex-col gap-10 max-w-[980px]">
        {TimeLines.map((timeItem, i) => {
          return (
            <div className="grid grid-cols-3" key={i}>
              <p className="col-span-1 fade-animation h2 text-royal-blue font-medium">
                {timeItem.year}
              </p>
              <Timeline
                mode="left"
                className="w-full col-span-2"
                items={timeItem.item.map((line) => {
                  return {
                    children: (
                      <IndividualTimeline
                        label={line.label}
                        className={line.checked ? "text-gray-300" : ""}
                      />
                    ),
                    dot: <BlueDot />,
                  };
                })}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default History;
