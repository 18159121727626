import React from "react";
import RoundedBorader from "../../components/text/RoundedBorader";

const Organization = () => {
  return (
    <section
      className="w-full h-auto gap-5 py-10 bg-center bg-no-repeat bg-cover align-col-center"
      style={{ backgroundImage: `url(/img/about-us/organization_bg.jpg)` }}
    >
      <div className="text-center fade-animation align-col-center">
        <RoundedBorader
          label="CERTIFICATION STATUS"
          className="text-white border-none"
        />
        <h2>
          <p className="text-white h2">조직도</p>
        </h2>
      </div>
      <img
        src="/img/about-us/dayone_organization.png"
        alt="데이원 조직도"
        className="fade-animation w-[320px] h-auto"
      />
    </section>
  );
};

export default Organization;
