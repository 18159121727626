import RoundedBorader from "mobile/components/text/RoundedBorader";

const Effects = () => {
  return (
    <section className="w-full h-auto align-col-center gap-10 py-10">
      <div className="text-center">
        <RoundedBorader
          label="EXPECTED EFFECTS OF INTRODUCTION"
          className="border-none"
        />
        <h1 className="fade-animation">
          <p className="h2">
            도입 <span className="text-royal-blue">기대 효과</span>
          </p>
        </h1>
      </div>

      <div className="flex flex-col gap-5 mx-5">
        <div className="mx-5">
          <div className="flex items-center gap-2 h5">
            <div className="align-center w-12 h-12 text-white rounded-full bg-deep-navy shadow-md">
              기술
            </div>
            <span className="h5 font-semibold text-black">
              기술 의존성 탈피
            </span>
          </div>
          <ul className="h6 border-b-2 border-blue-300 p-5 list-disc list-inside break-keep">
            <li>Web 표준 기술로 서비스 개발</li>
            <li>특정 플랫폼에 종속된 기술/인력 보유 불필요</li>
          </ul>
        </div>

        <div className="mx-5">
          <div className="h5 flex items-center gap-2 ">
            <div className="align-center w-12 h-12 text-white rounded-full bg-deep-navy shadow-md">
              효율
            </div>
            <span className="font-semibold text-black">생산/관리 효율성</span>
          </div>
          <ul className="h6 border-b-2 border-blue-300 p-5 list-disc list-inside break-keep">
            <li>Web 표준 기술로 서비스 개발</li>
            <li>소스 코드의 재사용성 향상. 운영/유지 보수 비용 절감</li>
          </ul>
        </div>

        <div className="mx-5">
          <div className="h5 flex items-center gap-2">
            <div className="align-center w-12 h-12 text-white rounded-full bg-deep-navy shadow-md">
              확장
            </div>
            <span className="font-semibold text-black">기능 확장성</span>
          </div>
          <ul className="h6 border-b-2 border-blue-300 p-5 list-disc list-inside break-keep">
            <li>모바일 단말의 기능 및 기술 트랜드의 빠른 변화</li>
            <li>
              M-FLOW의 확장 APIs를 통해 서비스 내 빠른 신 기술 및 기능 도입
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Effects;
