import React from "react";

const CompanyOverview = () => {
  return (
    <section className="w-full h-auto align-col-center bg-white gap-5 px-5 py-20">
      <img
        src={`/img/logo_color.png`}
        alt="dayone_logo"
        className="fade-animation w-[130px] h-[73px]"
      />
      <span className="h2 text-center break-keep">
        데이원은 <span className="text-royal-blue">제조 통신 금융</span>{" "}
        <span className="text-royal-blue">SI 컨설팅 서비스</span>​ 를 제공합니다
      </span>
      <img
        src="/img/home/m_three_circle.png"
        alt="데이원 소개"
        className="fade-animation w-[280px] h-auto"
      />
      <div className="h6 text-center grid gap-10">
        <span>
          국내 반도체 관련 서비스 개발 사업자로 선도적 기술력과 지속적인
          고객만족 경영을 바탕으로,{" "}
          <span className="text-royal-blue">
            공장 자동화 기술 중심의 다양한 상품구성을 통한 차별화된
            업무/비지니스 서비스를 제공
          </span>
          합니다.
        </span>
        <span>
          또한 통신 금융분야 플랫폼, 서비스 구축에 많은 경험을 가지고 있으며
          최근에는{" "}
          <span className="text-royal-blue">
            AI, BigData, Smart Factory Business 영역까지 점진적으로 사업을 확대
          </span>
          해 나갈 예정입니다.
        </span>
      </div>
    </section>
  );
};

export default CompanyOverview;
