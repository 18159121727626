import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";
import { Dropdown } from "antd";

const Nav = () => {
  const nav = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const pathName = window.location.pathname || "/";
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNavigate = (path: string) => {
    navigate(path);
    scrollToTop();
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollPos > currentScrollPos;
      setPrevScrollPos(currentScrollPos);
      setVisible(visible);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const scrollStyle = () => {
    if (prevScrollPos < 100) {
      if (pathName === "/") return "bg-transparent";
      else return "bg-deep-navy";
    } else if (prevScrollPos < 500) {
      return "bg-deep-navy";
    } else {
      if (visible) return "bg-deep-navy translate-y-0";
      else return "bg-deep-navy -translate-y-[95px]";
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <button type="button" onClick={() => handleNavigate("/solution/mflow")}>
          M-Flow
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <button
          type="button"
          onClick={() => handleNavigate("/solution/docblend")}
        >
          Docblend
        </button>
      ),
    },
  ];
  return (
    <nav ref={nav} className={`w-full`}>
      <div
        className={`navbar min-w-[980px] w-full h-[95px] text-white text-xl font-bold ${scrollStyle()}`}
      >
        <div className="flex justify-between px-8">
          <img
            src={"/img/logo_white.png"}
            alt="dayone_logo"
            className="w-auto h-[95px] px-5 cursor-pointer"
            onClick={() => handleNavigate("/")}
          />
          <ul className="flex w-[634px] items-center gap-10 justify-end px-5">
            <li
              className={`hover:text-sky ${
                pathName === "/about-us" && "text-sky"
              }`}
            >
              <button type="button" onClick={() => handleNavigate("/about-us")}>
                회사소개
              </button>
            </li>
            <li
              className={`hover:text-sky ${pathName === "/si" && "text-sky"}`}
            >
              <button type="button" onClick={() => handleNavigate("/si")}>
                SI
              </button>
            </li>
            <li
              className={`hover:text-sky ${
                pathName.includes("/solution") && "text-sky"
              }`}
            >
              <Dropdown menu={{ items }} placement="bottom">
                <button>솔루션</button>
              </Dropdown>
            </li>
            <li
              className={`hover:text-sky ${
                pathName === "/contact" && "text-sky"
              }`}
            >
              <button type="button" onClick={() => handleNavigate("/contact")}>
                문의하기
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
