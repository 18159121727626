import React from "react";
import RoundedBorader from "pc/components/text/RoundedBorader";

const Partnership = () => {
  return (
    <section
      className="min-w-[980px] w-full h-[890px] bg-cover bg-center bg-no-repeat align-col-center gap-20"
      style={{ backgroundImage: `url(/img/about-us/partnership_bg.jpg)` }}
    >
      <div className="gap-2 text-center align-col-center">
        <RoundedBorader
          label="KEY PARTNERS"
          className="text-white border-none"
        />
        <h2 className="text-5xl font-bold text-white fade-animation h2">
          주요 파트너사
        </h2>
        <p className="mt-8 tracking-wide text-white h5-t">
          데이원 솔루션(주)은 주요 파트너사인 SK하이닉스, SK C&C, 그 외 기업과
          함께합니다
        </p>
      </div>
      <div className="flex items-center gap-32">
        <img
          src="/img/about-us/SK-hynix.png"
          alt="sk-hynix"
          className="fade-animation w-[306px] h-[168px]"
        />
        <div className="bg-white w-[3px] h-[330px]" />
        <img
          src="/img/about-us/SK-c&c.png"
          alt="sk-c&c"
          className="fade-animation w-[310px] h-[158px]"
        />
      </div>
    </section>
  );
};

export default Partnership;
