import React from "react";

import MapNaver from "../../components/map/MapNaver";
import ContactForm from "../../components/form/ContactForm";

const Contact = () => {
  return (
    <section
      className="h-[1150px] w-full max-h-auto py-20 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(/img/home/contact.jpg)` }}
    >
      <div className="max-w-[1440px] mx-auto">
        <div className="flex justify-between mx-10 mb-20">
          <div className="flex flex-col text-white ">
            <p className="tracking-tight title-h1 py-7">CONTACT</p>
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-3">
                <img
                  src="/icon/location.png"
                  alt="location"
                  className="w-[28px] h-[28px]"
                />
                <span className="tracking-normal h6">
                  서울 강남구 강남대로 62길 28 4층 데이원 솔루션(주)
                </span>
              </div>
              <div className="flex items-center gap-3">
                <img
                  src="/icon/call.png"
                  alt="call"
                  className="w-[28px] h-[28px]"
                />
                <span className="tracking-normal h6">02)422.7532</span>
              </div>
              <div className="flex items-center gap-3">
                <img
                  src="/icon/message.png"
                  alt="message"
                  className="w-[28px] h-[28px]"
                />

                <span className="tracking-normal h6">
                  <a
                    data-auto-recognition="true"
                    href="mailto:jylee@dayone1.kr"
                  >
                    jylee@dayone1.kr
                  </a>
                </span>
              </div>
            </div>
          </div>
          <ContactForm />
        </div>
        <hr />
        <div className="bg-white max-w-[1354px] w-full box-border h-[400px] mt-10 mx-auto">
          <MapNaver />
        </div>
      </div>
    </section>
  );
};

export default Contact;
