import React from "react";
import RoundedBorader from "pc/components/text/RoundedBorader";

const ProvideFunc = () => {
  return (
    <section className="w-full h-auto align-col-center gap-10 bg-[#F5F5F5] py-10">
      <div className="text-center ">
        <RoundedBorader label="FPROVIDED FUNCTION" className="border-none" />
        <h1 className="fade-animation">
          <p className="h2">
            <span className="text-royal-blue">제공</span> 기능
          </p>
        </h1>
      </div>
      <p className="text-center h6 break-keep mx-5">
        (주)데이원의 솔루션 DOCBLEND을 통해 Microsoft Word / PowerPoint / Excel
        파일을 pdf, html로 변환 가능하며
        <br />
        또한 pdf를 html 파일로, html을 pdf파일로 문서변환기능을 제공합니다.
      </p>
      <img
        src="/img/solution/doc_format.png"
        alt="문서 변환 기능"
        className="fade-animation w-[280px] h-auto"
      />
    </section>
  );
};

export default ProvideFunc;
