import React from "react";

const BusinessHistory = () => {
  return (
    <section className="w-full h-auto pt-32 pb-10 align-col-center">
      <div className="text-center">
        <h1>
          <p className="mb-8 fade-animation h2">주요 사업 이력</p>
        </h1>
        <p className="fade-animation h1 text-royal-gray leading-[0.3] -z-10">
          BUSINESS HISTORY
        </p>
        <p className="mx-5 h6 break-keep">
          데이원 솔루션(주)은{" "}
          <span className="text-royal-blue">
            최적의 솔루션을 제시하고 높은 기술력
          </span>
          을 바탕으로 ​업계에{" "}
          <span className="text-royal-blue">
            새로운 트렌드를 선보이며 다양한 IT 시스템을 구축
          </span>
          해 나갑니다.
        </p>
      </div>

      <div className="bg-[#6684be80] w-[2px] h-[50px] mx-auto my-5" />

      <div className="grid grid-cols-2 text-white gap-[1px] p-2">
        <div
          className="col-span-1 min-w-[180px] min-h-[180px] flex flex-col justify-center"
          style={{ backgroundImage: `url(/img/si/business_1.png)` }}
        >
          <img
            src="/img/si/business_1_icon.png"
            alt="she"
            className="w-auto h-[60px] mx-auto"
          />
          <p className="text-lg font-semibold text-center">SHE</p>
          <p className="text-sm font-light text-center break-keep">
            (Safety,Health,Environment)
          </p>
        </div>

        <div
          className="col-span-1 min-w-[180px] min-h-[180px] flex flex-col justify-center"
          style={{ backgroundImage: `url(/img/si/business_2.png)` }}
        >
          <img
            src="/img/si/business_2_icon.png"
            alt="Audit Central Sys"
            className="w-auto h-[60px] mx-auto"
          />
          <p className="pt-5 text-lg font-semibold text-center">
            Audit Central Sys
          </p>
        </div>
        <div
          className="col-span-1 min-w-[180px] min-h-[180px] flex flex-col justify-center"
          style={{ backgroundImage: `url(/img/si/business_3.png)` }}
        >
          <img
            src="/img/si/business_3_icon.png"
            alt="제조 가동률 시스템"
            className="w-auto h-[60px] mx-auto"
          />
          <p className="pt-5 text-lg font-semibold text-center">
            제조 가동률 시스템
          </p>
        </div>

        <div
          className="col-span-1 min-w-[180px] min-h-[180px] flex flex-col justify-center"
          style={{ backgroundImage: `url(/img/si/business_4.png)` }}
        >
          <img
            src="/img/si/business_4_icon.png"
            alt="문서 표준화 관리 시스템"
            className="w-auto h-[60px] mx-auto"
          />
          <p className="pt-5 text-lg font-semibold text-center">
            문서 표준화 관리 시스템
          </p>
        </div>
      </div>
    </section>
  );
};

export default BusinessHistory;
