import React, { useEffect } from "react";
import { fadeInAnimation } from "pc/animation/fadeIn";
import ContactUs from "pc/container/contact/ContactUs";

const Contact = () => {
  useEffect(() => {
    fadeInAnimation();
  }, []);
  return (
    <div>
      <ContactUs />
    </div>
  );
};

export default Contact;
