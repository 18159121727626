import React from "react";

const ScrollTopButton = () => {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <button
      type="button"
      onClick={scrollTop}
      className="fixed z-20 text-lg bg-indigo w-12 h-12 mx-5 mb-10 shadow-lg rounded-full text-white bottom-0 right-0"
    >
      ▲
    </button>
  );
};

export default ScrollTopButton;
