import React from "react";
import RoundedBorader from "../../components/text/RoundedBorader";

const Technoliogy = () => {
  return (
    <section className="bg-white w-full h-auto align-col-center my-10 gap-5">
      <div className="text-center align-col-center gap-5 mx-5">
        <div>
          <RoundedBorader
            label="RETAINED TECHNOLIOGY"
            className="border-none"
          />
          <h2>
            <p className="fade-animation h2">
              <span className="text-royal-blue">사업</span> 분야
            </p>
          </h2>
        </div>
        <p className="h6 break-keep">
          데이원 솔루션(주)은 자바 플랫폼, C#, RDBMS(Oracle, Postages, MySql),
          React 기술을 보유함으로써 최적의 솔루션을 도출하여 효율적이고 안정적인
          사업을 수행합니다
        </p>
      </div>
      <img
        src="/img/about-us/skill_stack.png"
        alt="dayone skill"
        className="fade-animation h-auto w-[280px]"
      />
    </section>
  );
};

export default Technoliogy;
