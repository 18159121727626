import React, { useEffect, Suspense } from "react";
import Intro from "../container/home/Intro";
import CompanyOverview from "../container/home/CompanyOverview";
import MainBusinessSegments from "../container/home/MainBusinessSegments";
import Contact from "../container/home/Contact";
import { fadeInAnimation } from "../animation/fadeIn";

const Home = () => {
  useEffect(() => {
    fadeInAnimation();
  });
  return (
    <main>
      <Intro />
      <CompanyOverview />
      <MainBusinessSegments />
      <Suspense fallback={null}>
        <Contact />
      </Suspense>
    </main>
  );
};

export default Home;
