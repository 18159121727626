import React from "react";
import RoundedBorader from "mobile/components/text/RoundedBorader";

const ManufacturingSys = () => {
  return (
    <section className="w-full h-auto align-col-center gap-10 bg-indigo text-white py-10">
      <RoundedBorader
        label="MAJOR BUSINESS HISTORY 3"
        className="border-white text-white"
      />
      <h1>
        <p className="fade-animation h2 text-white">제조 가동률 시스템</p>
      </h1>
      <img
        src="/img/si/system.png"
        alt="제조 가동률 시스템"
        className="fade-animation w-[280px] h-auto"
      />
      <p className="h6 text-center break-keep mx-5">
        제조 설비는 투입 대비 양산 되는 제품에 대한 가동률 산출 및 관리가
        필요합니다.
        <br />
        제조 설비의 투자를 위한 Capex의 데이터로 활용합니다.
        <br />
        ​제조 가동률 시스템은 다양한 분야에서 생산되는 양산 제품에 대한 가동률
        산출 및 다양한 Report를 제공합니다.
      </p>
    </section>
  );
};

export default ManufacturingSys;
