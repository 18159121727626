import RoundedBorader from "mobile/components/text/RoundedBorader";

const MflowIntro = () => {
  return (
    <section className="w-full h-auto align-col-center pt-32 pb-10 gap-10">
      <div className="text-center">
        <RoundedBorader label="DAYONE SOLUTION" className="border-none" />
        <h1 className="fade-animation">
          <p className="h2 text-royal-blue">M-FLOW</p>
        </h1>
      </div>
      <img
        src="/img/solution/mflow.png"
        alt="mflow"
        className="fade-animation w-[280px] h-auto"
      />
      <p className="text-center h6 break-keep mx-5">
        기업 모바일 앱 서비스, M-FLOW라면 어렵지 않습니다.
        <br />
        웹 표준 기술인 HTML5, CSS, JavaScript로 모바일 앱 서비스가 가능해
        집니다.
        <br />
        기업의{" "}
        <span className="text-royal-blue">
          모바일 앱 서비스를 가장 쉽고 빠르게 제공하는 방법 M-FLOW
        </span>
        와 함께하세요.
      </p>
    </section>
  );
};

export default MflowIntro;
