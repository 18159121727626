import React from "react";
import RoundedBorader from "pc/components/text/RoundedBorader";

const Effects = () => {
  return (
    <section className="min-w-[980px] w-full h-[870px] align-col-center gap-10">
      <div className="text-center ">
        <RoundedBorader
          label="EXPECTED EFFECTS OF INTRODUCTION"
          className="border-none"
        />
        <h1 className="fade-animation">
          <p className="h1">
            도입 <span className="text-royal-blue">기대 효과</span>
          </p>
        </h1>
      </div>

      <img
        src="/img/solution/effect.png"
        alt="문서 변환 기능"
        className="fade-animation "
      />
      <p className="text-center sub">
        데이원의 솔루션(주)의 솔루션인 DOCBLEND 문서 변환 기능을 통해
        <br />
        <span className="text-royal-blue">
          디지털자산, 생산성 향상, 지식 Data Insight, AI 서비스 연계 효과를 기대
        </span>
        하실 수 있습니다.
      </p>
    </section>
  );
};

export default Effects;
