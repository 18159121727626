import React from "react";

const Intro = () => {
  return (
    <section
      className="min-w-[980px] w-full h-[720px] bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(/img/home/union.jpg)` }}
    >
      <div className="h-[720px] flex flex-col items-center justify-center text-white text-xl gap-12">
        <p className="text-center fade-animation title-h1">
          열정 없이는 위대한
          <br />
          일을 해낼 수 없다
        </p>
        <span className="tracking-tighter text-center fade-animation Pretendard-regular h5 text-light-gray">
          작은 아이디어가 발전하여 수많은 고객을 상대하는 글로벌 기업으로 성장할
          수 있었던 것은
          <br />
          첨단 기술이 바탕이 된 혁신적인 아이디어와 서비스 덕분이었습니다.
          <br />
          업계에 새로운 트렌드를 선보이며 빠르게 성장하는 기업 데이원과 함께
          해주세요.
        </span>
      </div>
    </section>
  );
};

export default Intro;
