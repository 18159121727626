import React from "react";

const Intro = () => {
  return (
    <section
      className="w-full h-[486px] bg-center bg-cover bg-no-repeat px-5"
      style={{ backgroundImage: `url(/img/home/union.jpg)` }}
    >
      <div className="h-[486px] flex flex-col items-center justify-center text-white gap-12">
        <p className="text-center fade-animation h1">
          열정 없이는 위대한
          <br />
          일을 해낼 수 없다
        </p>
        <span className="tracking-tighter text-center fade-animation sub text-light-gray break-keep">
          작은 아이디어가 발전하여 수많은 고객을 상대하는 글로벌 기업으로 성장할
          수 있었던 것은 첨단 기술이 바탕이 된 혁신적인 아이디어와 서비스
          덕분이었습니다. 업계에 새로운 트렌드를 선보이며 빠르게 성장하는 기업
          데이원과 함께 해주세요.
        </span>
      </div>
    </section>
  );
};

export default Intro;
