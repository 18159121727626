import React from "react";
import RoundedBorader from "pc/components/text/RoundedBorader";

const Intro = () => {
  return (
    <section
      className="min-w-[980px] w-full h-[1150px] bg-cover bg-center bg-no-repeat align-col-center gap-20"
      style={{ backgroundImage: `url(/img/about-us/aboutus.png)` }}
    >
      <div className="fade-animation text-center align-col-center gap-2">
        <RoundedBorader label="WHO WE ARE" className="border-none" />
        <p className="h3">2019년 설립된 데이원 솔루션(주)은</p>
        <p className="h2 tracking-wide">
          <span className="text-royal-blue">제조 통신 금융 SI</span> 및 컨설팅의
          <br />
          <span className="text-royal-blue">솔루션 전문</span> 기업입니다.
        </p>
      </div>
      <div className="fade-animation flex">
        <div className="w-[300px] h-[300px] bg-[#819EE2] text-white flex flex-col justify-evenly items-center break-keep">
          <p className="text-md text-center">
            <b>공장 자동화 기술</b>
            <br />
            차별화된 서비스
          </p>
          <img
            src="/img/about-us/factory_machine.png"
            alt="공장 자동화 기술"
            className="w-[135px] h-[135px] mx-auto"
          />
        </div>
        <div className="w-[300px] h-[300px] bg-[#0D1242] text-white flex flex-col justify-evenly items-center break-keep">
          <p className="text-md text-center">
            <b>통신 금융분야 플랫폼</b>
            <br />
            폭넓은 데이터 보유
          </p>
          <img
            src="/img/about-us/handphone.png"
            alt="통신 금융분야 플랫폼"
            className="w-[135px] h-[135px] mx-auto"
          />
        </div>
        <div className="w-[300px] h-[300px] bg-[#050F33] text-white flex flex-col justify-evenly items-center break-keep">
          <p className="text-md text-center">
            <b>
              AI, BigData,
              <br />
              Smart Factory Business
            </b>
          </p>
          <img
            src="/img/about-us/brain.png"
            alt="AI, BigData, Smart Factory"
            className="w-[135px] h-[135px] mx-auto"
          />
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <p className="h5-t text-center">
          국내 반도체 관련 서비스 개발 사업자로 선도적 기술력과 지속적인
          고객만족 경영을 바탕으로,
          <br />
          <span className="text-royal-blue">
            공장 자동화 기술 중심의 다양한 상춤구성을 통한 차별화된
            업무/비지니스 서비스를 제공
          </span>
          합니다
        </p>
        <p className="h5-t text-center">
          또한 통신 금융분야 플랫폼, 서비스 구축에 많은 경험을 가지고 있으며
          최근에는{" "}
          <span className="text-royal-blue">
            AI, BigData,
            <br />
            Smart Factory Business 영역까지 점진적으로 사업을 확대
          </span>
          해 나갈 예정입니다
        </p>
      </div>
    </section>
  );
};

export default Intro;
